import React from 'react';
import { Box, Heading, Text, Link, Button, UnorderedList, ListItem} from '@chakra-ui/react';


export default function About(){
    return (
        <Box bg="white" shadow="md" rounded="lg" p={6} mt={8}>
            <Text fontSize="2xl" fontWeight="semibold" color="gray.800" mb={4} fontFamily="Montserrat, sans-serif">
              About Our Library
            </Text>
            <Text color="gray.600" mb={4}>
              The Bangladesh Conflict Watch Library is a comprehensive collection of resources related to conflicts and peace-building efforts in Bangladesh. Our library includes:
            </Text>
            <Box as="ul" pl={4} color="gray.600" mb={4}>
              <Text as="li">Research reports and academic papers</Text>
              <Text as="li">Policy briefs and recommendations</Text>
              <Text as="li">News articles and media reports</Text>
              <Text as="li">Case studies and conflict analyses</Text>
              <Text as="li">Peace-building strategies and best practices</Text>
            </Box>
            <Text color="gray.600">
              We aim to provide researchers, policymakers, and peace activists with valuable information to understand and address conflicts in Bangladesh. If you have a resource to contribute, please consider uploading it to our archive.
            </Text>
            <Button colorScheme="red" variant="outline" mt={4}>
              <Link href="/archive_upload">Upload to Archive</Link>
            </Button>
        </Box>

      
    );
};