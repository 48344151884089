import { Box, Text, Link, Grid, Heading, Flex } from '@chakra-ui/react';

const Footer = () => {
	return (
		<Box bg="gray.800" color="white" py={8}>
			<Box maxW="98vw" mx="auto" px={4}>
				<Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={8} >
					{/* Quick Links */}
					<Box marginInline={'auto'} width={'80%'}>
						<Heading
							as="h3"
							fontSize="lg"
							fontWeight="semibold"
							mb={4}
							fontFamily="Montserrat, sans-serif"
						>
							Quick Links
						</Heading>
						<Box
							as="ul"
							listStyleType="none"
							//m={0} 
							//p={0} 
							spacing={2}
						>
							<Text as="li" mb={2}>
								<Link href="/" _hover={{ color: 'red.400' }}>
									Home
								</Link>
							</Text>
							<Text as="li" mb={2}>
								<Link href="/report" _hover={{ color: 'red.400' }}>
									Report an Incident
								</Link>
							</Text>
							<Text as="li" mb={2}>
								<Link href="/library" _hover={{ color: 'red.400' }}>
									Library
								</Link>
							</Text>
							<Text as="li" mb={2}>
								<Link href="/archive_upload" _hover={{ color: 'red.400' }}>
									Upload to Archive
								</Link>
							</Text>
							<Text as="li" mb={2}>
								<Link href="/archive_list" _hover={{ color: 'red.400' }}>Archive List</Link>
							</Text>
							<Text as="li" mb={2}>
								<Link href="/about_us" _hover={{ color: 'red.400' }}>About Us</Link>
							</Text>
						</Box>
					</Box>

					{/* Contact Us */}
					<Box marginInline={'auto'} width={'80%'} >
						<Heading as="h3" fontSize="lg" fontWeight="semibold" mb={4} fontFamily="Montserrat, sans-serif">
							Contact Us
						</Heading>
						<Text>
							Email: info@bdconflictwatch.org
						</Text>
						<Text>
							Phone: +880 1234 567890
						</Text>
						<Text>
							Address: Dhaka, Bangladesh
						</Text>
					</Box>

					{/* Follow Us */}
					<Box marginInline={'auto'} width='80%' >
						<Heading
							as="h3"
							fontSize="lg"
							fontWeight="semibold"
							mb={4}
							fontFamily="Montserrat, sans-serif"
						>
							Follow Us
						</Heading>
						<Flex align="center" justify="flex-start" gap={4} >
							<Link href="https://www.facebook.com/icsforum" isExternal _hover={{ color: 'red.400' }}>
								Facebook
							</Link>
							<Link href="https://x.com/icsforum" isExternal _hover={{ color: 'red.400' }}>
								Twitter
							</Link>
							<Link href="#" isExternal _hover={{ color: 'red.400' }}>
								Instagram
							</Link>
						</Flex>
					</Box>
				</Grid>

				<Text textAlign="center" fontSize="sm" mt={8}>
					© 2024 Bangladesh Conflict Watch. All rights reserved.
				</Text>
			</Box>
		</Box>
	);
};

export default Footer;
