import { createBrowserRouter, } from "react-router-dom";
import Main from "../Layouts/Main";
import Incident from "../Pages/Incident.jsx";
import LogIn from "../Pages/LogInNew.jsx";
import Register from "../Pages/Register";
import UserRoute from "./UserRoute";
import LandingPage from "../Pages/LandingPage";
//import ArchiveUpload from "../Pages/ArchiveUpload";
// import LinkToPdf from "../Pages/LinkToPdf";
// import ListDocuments from "../Pages/ListDocuments";
// import ArchiveUpload from "../Pages/ArchiveUpload";
// import ListArchive from "../Pages/ListArchive";
import UserList from "../Pages/UserList";
import Tag from "../Pages/Tag";
import Event from "../Pages/Event";
import AdminRoute from "./AdminRoute";
import SuperAdminRoute from "./SuperAdminRoute";
import ReportIncident from "../Pages/ReportIncident";
import ArchiveUploadPage from "../Pages/ArchiveUploadPage";
//import ConflictLibrary from "../Pages/ConflictLibrary";
// import Library from "../Pages/Library/index.js";
//import FinalDesignLibrary from "../Pages/FinalDesignLibrary/index.js";
import UpdatePassword from "../Pages/UpdatePassword.jsx";
import ArchiveListPage from "../Pages/ArchiveList/index.js";
import Home from "../Pages/Home.jsx";
import Dashboard from "../Pages/Dashboard.jsx";
// import FinalDesignLibrary from "../Pages/FinalDesignLibrary/index.js";
// import UpdatePassword from "../Pages/UpdatePassword.jsx";
// import ArchiveListPage from "../Pages/ArchiveList/index.js";
// import Home from "../Pages/Home.jsx";
import UserProfile from "../Pages/UserProfile/index.jsx";
//import LogIn from "../Pages/LogIn.jsx";
import LibraryNew from "../Pages/LibraryNew/index.jsx";
import AboutUs from "../Pages/AboutUs.jsx";


export const routes = createBrowserRouter([
	{
		path: '/',
		element: <Main />,
		// element: <UserRoute><Main /></UserRoute>,
		// errorElement: <ErrorPage></ErrorPage>,
		children: [
			{
				path: '/',
				// loader: () => fetch('https://re-books-server.vercel.app/'),
				element: <LandingPage />,
			},
			{
				path: '/home',
				element: <UserRoute><Home /></UserRoute>,
			},
			{
				path: '/dashboard',
				element: <Dashboard />,
			},
			{
				path: '/about_us',
				element: <AboutUs />,
			},
			{
				path: '/incident',
				element: <UserRoute><Incident /></UserRoute>,
			},
			{
				path: '/report',
				element: <UserRoute><ReportIncident /></UserRoute>,
			},
			{
				path: '/archive_upload',
				element: <UserRoute><ArchiveUploadPage /></UserRoute>,
			},
			{
				path: '/user_list',
				element: <UserRoute><SuperAdminRoute><UserList /></SuperAdminRoute></UserRoute>,
			},
			{
				path: '/tag',
				element: <UserRoute><AdminRoute><Tag /></AdminRoute></UserRoute>,
			},
			{
				path: '/update_password',
				element: <UserRoute><UpdatePassword /></UserRoute>
			},
			{
				path: '/archive_list',
				element: <UserRoute><ArchiveListPage /></UserRoute>
			},
			{
				path: '/event',
				element: <UserRoute><AdminRoute><Event /></AdminRoute></UserRoute>,
			},
			{
				path: '/library',
				element: <UserRoute><LibraryNew /></UserRoute>,
			},
			{
				path: '/profile',
				element: <UserRoute><UserProfile /></UserRoute>,
			},
		]
	},
	{
		path: '/login',
		element: <LogIn />
	},

	{
		path: '/register',
		element: <Register />
	},

	{
		path: '/librarynew',
		element: <LibraryNew />
	},
	//	{
	//		path: '/tag',
	//		element: <Tag />
	//	},
	//	{
	//		path: '/event',
	//		element: <Event />,
	//	},
	//	{
	//		path: '/reportIncident',
	//		element: <ReportIncident />
	//	},
	//	{
	//		path: '/archive_upload',
	//		element: <ArchiveUploadPage />
	//	},
	//	{
	//		path: '/conflictLibrary',
	//		element: <ConflictLibrary />
	//	},
	//	{
	//		path: '/library',
	//		element: <Library />,
	//	},
	//	{
	//		path: '/FinalDesignLibrary',
	//		element: <FinalDesignLibrary />
	//	},
	//	{
	//		path: '/archive_list',
	//		element: <ListArchive />,
	//	},
	//	{
	//		path: '/event',
	//		element: <Event />,
	//	},
	//	{
	//		path: '/reportIncident',
	//		element: <ReportIncident/>
	//	},
	// {
	// 	path: '/reportIncident',
	// 	element: <ReportIncident />
	// },
	//	{
	//		path: '/archive_upload',
	//		element: <ArchiveUploadPage />
	//	},

	// {
	// 	path: '/conflictLibrary',
	// 	element: <ConflictLibrary />
	// },

	//	{
	//		path: '/library',
	//		element: <Library />,
	//	},
	// {
	// 	path: '/FinalDesignLibrary',
	// 	element: <FinalDesignLibrary />
	// },
	//	{
	//		path: '/archive_upload',
	//		element: <ArchiveUpload />,
	//	},
	// {
	// 	path: '/archive_list',
	// 	element: <ListArchive />
	// },
	// {
	// 	path: '/archive_upload',
	// 	element: <ArchiveUpload />,
	// },
	//	{
	//		path: '/archive_list',
	//		element: <ArchiveListPage />
	//	},

	//	{
	//		//path: '/archive_list',
	//		path: '/archive_list',
	//		element: <ArchiveListPage />
	//	},
	//	{
	//		path: '/user_list',
	//		element:<UserList />,
	//	},
]);