import React, { useState } from 'react';
import { Box, Heading, Input, Button, Flex, Stack, FormControl, FormLabel, Text, MenuItem, Checkbox, MenuList, Menu, MenuButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Icon } from '@chakra-ui/react';
// import SearchPageButton from '../../../Components/SearchPageButton';
import TagField from "../../../Pages/Tag";
import EventField from "../../../Pages/Event";
import { FaCheck, FaClock, FaExclamationCircle } from 'react-icons/fa';

export default function SearchOptions({
    handleOptionToggle,
    selectedSearchOptions,
    categoriesOptions,
    setSelectedSearch,
    selectedSearch,
    setProcessed,
    handleAllDownload,
    handleSelectFileDownload,
    handleSelectAll,
    handleSelectClick,
    filteredData,
    downloadFileNo,
    selectFlag,
    downloadButtonText,
    selectButtonText,
    listSearchSelectedTags,
    setListSearchSelectedTags,
    listSearchSelectedEvents,
    setListSearchSelectedEvents,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleSearch,
}) {
    const { innerWidth } = window;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isDownloading, setIsDownloading] = useState(false);
    const handleDownload = () => {
        setIsDownloading(true);

        setTimeout(() => {
            setIsDownloading(false);
            onClose(); // Close modal after download
        }, 1500);

        if (!selectFlag) handleAllDownload();
        else handleSelectFileDownload();
    };


    return (
        <Box bg="white" shadow="md" rounded="lg" p={6} mb={8}>
            <Heading
                as="h2"
                fontSize="2xl"
                fontWeight="semibold"
                color="gray.800"
                mb={4}
                fontFamily="Montserrat, sans-serif"
                textAlign={'center'}
            >
                Search In Archive
            </Heading>

            <form>
                <Flex mb={4} gap={4} direction={{ base: 'column', md: 'row' }} w={"100%"}>
                    <FormControl w={["100%", "100%", "50%",]}>
                        <FormLabel >Source Type</FormLabel>
                        <Input
                            type="text"
                            placeholder="Search on Source Type Description"
                            px={2}
                            onChange={(e) => setSelectedSearch(e.target.value)}
                            value={selectedSearch}
                        />
                    </FormControl>

                    <FormControl w={["100%", "100%", "50%"]}>
                        <FormLabel >Categories</FormLabel>

                        <Box w={['100%',]}>
                            {/* Trigger button displaying selected options */}
                            <Menu closeOnSelect={false}>
                                <MenuButton as={Button} w="100%" boxShadow="sm">
                                    {selectedSearchOptions.length > 0
                                        ? `Selected: ${selectedSearchOptions.length} item(s)`
                                        : "Select Categories"}
                                </MenuButton>

                                {/* Dropdown list with checkboxes */}
                                <MenuList
                                    // w={["90%", "100%", "50%"]}
                                    w={[(innerWidth * 0.8), (innerWidth * 0.9), (innerWidth * 0.4)]}
                                // maxW="300px"
                                >
                                    {categoriesOptions.map((option) => (
                                        <MenuItem key={option.value} closeOnSelect={false}>
                                            <Checkbox
                                                isChecked={selectedSearchOptions.includes(option.value)}
                                                onChange={() => handleOptionToggle(option.value)}
                                            >
                                                {option.label}
                                            </Checkbox>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>

                            {/* Display the selected items below the dropdown, if any */}
                            <Box mt={2}>
                                {selectedSearchOptions.length > 0 && (
                                    <Text fontSize="sm" color="gray.600">
                                        Selected: {selectedSearchOptions.map((item) => categoriesOptions.find(opt => opt.value === item).label).join(', ')}
                                    </Text>
                                )}
                            </Box>
                        </Box>
                    </FormControl>
                </Flex>

                {/* tag/events/date search system  */}
                <Stack direction={["column", "column", "column", 'row']} width={['100%']} mb={4}>
                    <Stack direction={["column", "column", 'row']} width={['100%', '100%', '100%', '50%', '60%']}>
                        {/* Tags */}
                        <FormControl >
                            <FormLabel >Tags</FormLabel>
                            <TagField
                                embedded={true}
                                onlySearch={true}
                                listSearchSelectedTags={listSearchSelectedTags}
                                setListSearchSelectedTags={setListSearchSelectedTags}
                            />
                        </FormControl>

                        {/* Events */}
                        <FormControl>
                            <FormLabel>Events</FormLabel>
                            <EventField
                                embedded={true}
                                onlySearch={true}
                                listSearchSelectedEvents={listSearchSelectedEvents}
                                setListSearchSelectedEvents={setListSearchSelectedEvents}
                            />
                        </FormControl>
                    </Stack>

                    <Stack direction={["column", "row"]} width={['100%', '100%', '100%', '50%', '40%']}>
                        <Stack direction={["row",]} width={['100%', '80%', '80%', '80%']}>
                            <FormControl width={'50%'}>
                                <FormLabel>Start Date</FormLabel>
                                <Input
                                    type='date'
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </FormControl>

                            <FormControl width={'50%'}>
                                <FormLabel>End Date</FormLabel>
                                <Input
                                    type='date'
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </FormControl>
                        </Stack>

                        <Button
                            width={['100%', '20%']}
                            bg="red.600"
                            _hover={{ bg: 'red.700' }}
                            color="white"
                            mt={[0, 8]}
                            // type="submit"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                    </Stack>
                </Stack>

                {/* SearchPageButton */}
                <Flex
                    gap={1}
                    mb={6}
                    direction={{ base: "column", md: "row" }}
                    align={{ base: "stretch", md: "center" }}
                // px={["2", "4"]}
                // alignItems={["center", "center"]}
                >
                    <Button
                        variant="outline"
                        leftIcon={<Icon as={FaCheck} />}
                        colorScheme="green"
                        onClick={() => { setProcessed("processed") }}
                    >
                        Verified
                    </Button>

                    <Button
                        variant="outline"
                        leftIcon={<Icon as={FaClock} />}
                        colorScheme="yellow"
                        onClick={() => { setProcessed("inprogress") }}
                    >
                        Further Review
                    </Button>

                    <Button
                        variant="outline"
                        leftIcon={<Icon as={FaExclamationCircle} />}
                        colorScheme="blue"
                        onClick={() => { setProcessed("unprocessed") }}
                    >
                        New to the Queue
                    </Button>
                </Flex>

                {/* all file download in one click */}
                <Box
                    px={["2", "4"]}
                    display="flex"
                    flexDirection="column"
                    alignItems={["center", "center", "flex-end"]}
                    pb={'1rem'}
                >
                    <Stack direction={{ base: 'column', md: 'row' }} columnGap={1}>
                        <Button
                            //px={[1, 5]}
                            isDisabled={(filteredData.length > 0) ? false : true}
                            borderRadius="lg"
                            bg="gray.800"          // Background color
                            color="white"
                            _hover={{ bg: 'gray.900', color: "white" }}
                            //fontSize={["xs", "sm", "md"]}
                            width={{ base: "full", md: "auto" }}
                            px={{ base: 2, md: 4 }}  // Padding adjusted for different screen sizes
                            fontSize={{ base: "sm", md: "md" }}  // Smaller font size on mobile, normal on larger screens
                            // onClick={() => {
                            //     if (!selectFlag) handleAllDownload();
                            //     else handleSelectFileDownload();
                            // }}
                            onClick={onOpen}
                        >
                            {downloadButtonText}
                        </Button>

                        {selectFlag &&
                            <Button
                                isDisabled={(filteredData.length > 0) ? false : true}
                                borderRadius="lg"
                                bg="gray.800"          // Background color
                                _hover={{ bg: 'gray.900', color: "white" }}

                                color="white"
                                width={{ base: "full", md: "auto" }}
                                px={{ base: 1, md: 2 }}  // Padding adjusted for different screen sizes
                                fontSize={{ base: "sm", md: "md" }}  // Smaller font size on mobile, normal on larger screens
                                onClick={() => handleSelectAll()}
                            >
                                Select All
                            </Button>
                        }

                        <Button
                            isDisabled={(filteredData.length > 0) ? false : true}
                            borderRadius="lg"
                            bg="gray.800"          // Background color
                            _hover={{ bg: 'gray.900', color: "pink" }}
                            color="white"
                            width={{ base: "full", md: "auto" }}
                            fontSize={{ base: "sm", md: "md" }}  // Smaller font size on mobile, normal on larger screens
                            onClick={() => handleSelectClick(selectFlag)}
                        >
                            {selectButtonText}
                        </Button>
                    </Stack>
                </Box>
            </form>


            {/* download sure modal  */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Download File</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to download {downloadFileNo} file?
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            onClick={handleDownload}
                            isLoading={isDownloading}
                        >
                            Yes
                        </Button>
                        <Button onClick={onClose} ml={3}>
                            No
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};