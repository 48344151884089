import React, { useEffect, useState } from 'react';
import { forwardRef, useRef } from 'react';
import { IconButton, useMergeRefs, Button, Box, Checkbox, FormControl, FormLabel, Heading, Input, Link, Stack, Text, Image, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import toast from 'react-hot-toast';
import Captcha from '../Components/Captcha';
// import FBLogin from '../Components/FBLogin';
// import GoogleLoginButton from '../Components/GoogleLoginButton';

import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Redux/Thunk/Login';
import { selectIsLoggedIn, selectUserLoginErrorMessage } from '../Redux/Reducer';
import { updateSessionExpiry } from '../Redux/ActionCreator/login';


const LogIn = () => {
	const [showPassword, setShowPassword] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	var currentDate = new Date();
	currentDate.setMinutes(currentDate.getMinutes() + 3) //add one-eighty minutes to this time. 
	const nextDate = new Date(currentDate).getTime();

	// const isLoggedIn = true;
	const isLoggedIn = useSelector(state => selectIsLoggedIn(state));
	useEffect(() => {
		if (isLoggedIn) {
			navigate("/home");
		};
	}, [isLoggedIn, navigate]);

	// error message show
	const error = useSelector(state => selectUserLoginErrorMessage(state));
	const [count, setCount] = useState(0);
	useEffect(() => {
		if (error.message && count) {
			toast.error(error.message);
		}
	}, [count, error.message]);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	// for password field
	// const { isOpen, onToggle } = useDisclosure()
	const inputRef = useRef(null)
	const mergeRef = useMergeRefs(inputRef, forwardRef.ref)
	// const onClickReveal = () => {
	// 	onToggle()
	// 	if (inputRef.current) {
	// 		inputRef.current.focus({
	// 			preventScroll: true,
	// 		})
	// 	}
	// }

	const [captchaValue, setCaptchaValue] = useState(null);

	const handleSubmit = (event) => {
		event.preventDefault();

		if (!captchaValue) {
			alert('Please complete the CAPTCHA');
			return;
		}

		dispatch(login(email, password, 'admin', captchaValue));
		dispatch(updateSessionExpiry(nextDate));

		setEmail('');
		setPassword('');
		setCount(1);
	};

	return (
		<Flex
			minH="100vh"
			bg="#FFFF"
			align="center"
			justify="center"
			fontFamily="Lato, sans-serif"
		// p={{ base: 4, md: 8 }}
		>
			<Box
				//bg="whiteAlpha.800"
				//backdropFilter="blur(10px)"
				rounded="lg"
				p={{ base: 6, md: 8 }}
				w="full"
				maxW={{ base: "sm", md: "md" }}
				bg="#B9B9AA"
			>
				<Flex justify="center" mb={6}>
					{/*<Button
						onClick={() => navigate("/")}
						bg={"#B9B9AA"}
						 //color='white'
						//hover={{ bg: "#B9B9AA", color: "white" }}
					>*/}
					<Box borderRadius={10} p={2}>
						<Image
							src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Bangladesh%C2%A0Conflct%C2%A0Watch-5Zd8HndRbKDaTImTmW8mYi5x8nigYR.png"
							alt="Bangladesh Conflict Watch Logo"
							h={{ base: 12, md: 16 }}
						/>
					</Box>
					{/*</Button>*/}
				</Flex>

				<Heading
					as="h1"
					size="lg"
					textAlign="center"
					color="gray.900"
					mb={2}
					fontFamily="Montserrat, sans-serif"
				>
					Log in to your account
				</Heading>

				<Text textAlign="center" mb={6} color="gray.800">
					Don't have an account?{" "}
					<Link href="/register" color="gray.900" fontWeight="semibold" _hover={{ textDecoration: "underline" }}>
						Sign up
					</Link>
				</Text>

				<Stack as="form" spacing={4} onSubmit={handleSubmit}>
					<FormControl isRequired id="email">
						<FormLabel htmlFor="email" fontSize="sm" fontWeight="medium" color="gray.900" mb={1}>
							Email <Text as="span" color="red.500">*</Text>
						</FormLabel>

						<Input
							id="email"
							type="email"
							placeholder='Your Email Address'
							//px={2}
							//w={'100%'}
							bgColor={"white"}
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							isRequired
						/>
					</FormControl>

					<FormControl isRequired id="password">
						<FormLabel htmlFor="password" fontSize="sm" fontWeight="medium" color="gray.900" mb={1}>
							Password <Text as="span" color="red.500">*</Text>
						</FormLabel>

						<Box position="relative">
							<Input
								id="password"
								ref={mergeRef}
								name="password"
								type={showPassword ? "text" : "password"}
								autoComplete="current-password"
								placeholder="Type your Password here"
								bg="whiteAlpha.80"
								//px={2}
								//w={'90%'}
								pr="10"
								onChange={(e) => setPassword(e.target.value)}
								value={password}
								isRequired
							/>

							<IconButton
								aria-label="Toggle password visibility"
								icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
								position="absolute"
								right="3"
								top="50%"
								transform="translateY(-50%)"
								color="gray.500"
								variant="unstyled"
								onClick={() => setShowPassword(!showPassword)}
							/>
						</Box>
					</FormControl>

					<Flex align="center" justify="space-between">
						<Checkbox colorScheme="gray">
							Remember me
						</Checkbox>

						<Link href="/forgot-password" color="red.600" fontSize="sm" _hover={{ textDecoration: "underline" }}>
							Forgot password?
						</Link>
					</Flex>

					<Captcha onChange={setCaptchaValue} />

					<Button type="submit" w="full" bg="gray.900" _hover={{ bg: "gray.800" }} color="white">
						Sign in
					</Button>
				</Stack>

				{/* <Box mt={6}>
					<Text textAlign="center" fontSize="sm" color="gray.800" mb={4}>
						Login With
					</Text>

					<Flex justify="center" gap={4}>
						<Button
							variant="outline"
							bg="whiteAlpha.80"
							_hover={{ bg: "white" }}
							onClick={() => { }}
						>
							<svg width='28px' height='28px' fill="currentColor" viewBox="0 0 24 24">
								<path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
							</svg>
						</Button>

						<Button
							variant="outline"
							bg="whiteAlpha.80"
							_hover={{ bg: "white" }}
							onClick={() => { }}
						>
							<svg width='28px' height='28px' viewBox="0 0 24 24">
								<path
									fill="#EA4335"
									d="M5.266 9.765A7.077 7.077 0 0 1 12 4.909c1.69 0 3.218.6 4.418 1.582L19.91 3C17.782 1.145 15.055 0 12 0 7.27 0 3.198 2.698 1.24 6.65l4.026 3.115Z"
								/>
								<path
									fill="#34A853"
									d="M16.04 18.013c-1.09.703-2.474 1.078-4.04 1.078a7.077 7.077 0 0 1-6.723-4.823l-4.04 3.067A11.965 11.965 0 0 0 12 24c2.933 0 5.735-1.043 7.834-3l-3.793-2.987Z"
								/>
								<path
									fill="#4A90E2"
									d="M19.834 21c2.195-2.048 3.62-5.096 3.62-9 0-.71-.109-1.473-.272-2.182H12v4.637h6.436c-.317 1.559-1.17 2.766-2.395 3.558L19.834 21Z"
								/>
								<path
									fill="#FBBC05"
									d="M5.277 14.268A7.12 7.12 0 0 1 4.909 12c0-.782.125-1.533.357-2.235L1.24 6.65A11.934 11.934 0 0 0 0 12c0 1.92.445 3.73 1.237 5.335l4.04-3.067Z"
								/>
							</svg>
						</Button>
					</Flex>
				</Box> */}

				{/* <VStack mt={6}>
					<Text textAlign={'center'} fontWeight={'bold'}>Login With </Text>

					<HStack spacing={3} >
						<FBLogin />
						<GoogleLoginButton />
					</HStack>
				</VStack> */}
			</Box>
		</Flex>
	);
};

export default LogIn;
