import React, {
	useEffect,
	useState,
} from 'react';
import {
	Box,
	Button,
	Checkbox,
	Grid,
	GridItem,
	Input,
	Radio,
	RadioGroup,
	Stack,
	Text,
	Textarea,
	VStack,
} from '@chakra-ui/react';
import { Form } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../Layout/Layout';
import DescriptionCards from '../Components/DescriptionCards';
// import Captcha from '../Components/Captcha';
import Tags from './Tag';
import { categoriesInputHeader, victimInputHeader, victimInputPlaceholder, dateInputHeader, dateInputId, dateInputPlaceholder, descriptionInputHeader, descriptionInputId, descriptionInputPlaceholder, emailInputHeader, emailInputId, emailInputPlaceholder, fileInputHeader, instructionText, nameInputHeader, nameInputId, nameInputPlaceholder, addressInputHeader, addressInputId, addressInputPlaceholder, occupationInputHeader, occupationInputId, occupationInputPlaceholder, submitButtonText, submitWaitingText, victimInputId, welcomeText, shortDescriptionInputHeader, shortDescriptionInputId, shortDescriptionInputPlaceholder, attackPlaceCategoriesInputHeader, victimConditionInputHeader } from '../homeDynamicTexts';

import { selectToken, selectDataArchive, selectHistoryArchive, selectUserName } from '../Redux/Reducer';
import { archiveDataSubmit, archiveHistorySubmit } from '../Redux/Thunk/ArchiveUpload';


const Incident = () => {
	const dispatch = useDispatch();

	const token = useSelector(state => selectToken(state));
	// const profile = useSelector(state => selectProfile(state));
	const data = useSelector(state => selectDataArchive(state));
	const history = useSelector(state => selectHistoryArchive(state));
	const userName = useSelector(state => selectUserName(state));

	const [name, setName] = useState('');
	const handleNameChange = (e) => setName(e.target.value);

	const [email, setEmail] = useState('');
	const handleEmailChange = (e) => setEmail(e.target.value);

	const [victim, setVictim] = useState('');
	const handleVictimChange = (e) => setVictim(e.target.value);

	const [address, setAddress] = useState('');
	const handleAddressChange = (e) => setAddress(e.target.value);

	const [occupation, setOccupation] = useState('');
	const handleOccupationChange = (e) => setOccupation(e.target.value);

	const [selectedCategoriesOptions, setSelectedCategoriesOptions] = useState([]);
	const [categoriesOptions, setCategoriesOptions] = useState([
		{ value: 'শারীরিক আক্রমণ', label: 'শারীরিক আক্রমণ' },
		{ value: 'সম্পত্তির উপর আক্রমণ', label: 'সম্পত্তির উপর আক্রমণ' },
		{ value: 'অগ্নিকান্ড', label: 'অগ্নিকান্ড' },
		{ value: 'লুটপাট', label: 'লুটপাট' },
		{ value: 'হুমকি ও ভয় ভীতি', label: 'হুমকি ও ভয় ভীতি' },
		{ value: 'অনলাইন প্রচারণা, উসকানি, অনলাইন আক্রমণ', label: 'অনলাইন প্রচারণা, উসকানি, অনলাইন আক্রমণ' },
		{ value: 'নিগ্রহ', label: 'নিগ্রহ' },
		{ value: 'প্রযোজ্য নয়', label: 'প্রযোজ্য নয়' },
		{ value: 'Other', label: 'Other' },
	]);
	const handleCheckboxChange = (value) => {
		setSelectedCategoriesOptions((prev) =>
			prev.includes(value)
				? prev.filter((item) => item !== value)
				: [...prev, value]
		);
	};

	const [selectedAttackPlaceCategoriesOptions, setSelectedAttackPlaceCategoriesOptions] = useState([]);
	const [attackPlaceCategoriesOptions, setAttackPlaceCategoriesOptions] = useState([
		{ value: 'গৃহ', label: 'গৃহ' },
		{ value: 'কর্মক্ষেত্র', label: 'কর্মক্ষেত্র' },
		{ value: 'অগ্নিকান্ড', label: 'অগ্নিকান্ড' },
		{ value: 'উপাসনার স্থান', label: 'উপাসনার স্থান' },
		{ value: 'স্থাপনা', label: 'স্থাপনা' },
		{ value: 'সুনির্দিষ্ট নয়', label: 'সুনির্দিষ্ট নয়' },
		{ value: 'প্রযোজ্য নয়', label: 'প্রযোজ্য নয়' },
		{ value: 'Other', label: 'Other' },
	]);
	const handleAttackPlaceCheckboxChange = (value) => {
		setSelectedAttackPlaceCategoriesOptions((prev) =>
			prev.includes(value)
				? prev.filter((item) => item !== value)
				: [...prev, value]
		);
	};

	const [victimConditionOptions, setVictimConditionOptions] = useState([
		{ value: 'মৃত', label: 'মৃত' },
		{ value: 'আহত', label: 'আহত' },
		{ value: 'নিখোঁজ', label: 'নিখোঁজ' },
		{ value: 'প্রযোজ্য নয়', label: 'প্রযোজ্য নয়' },
	]);
	const [victimConditionOptionValue, setVictimConditionOptionValue] = useState("");

	const [shortDescription, setShortDescription] = useState('');
	const handleShortDescriptionChange = (e) => setShortDescription(e.target.value);

	const [description, setDescription] = useState('');
	const handleDescriptionChange = (e) => setDescription(e.target.value);

	const [date, setDate] = useState('');
	const handleDateChange = (e) => setDate(e.target.value);


	// file upload 
	const [uploadFlag, setUploadFlag] = useState(false);
	// for multiple file system
	const [selectedFiles, setSelectedFiles] = useState(null);
	const [previews, setPreviews] = useState([]);
	const [error, setError] = useState('');
	useEffect(() => {
		// Cleanup the object URLs when component unmounts or previews change
		return () => {
			previews.forEach(file => URL.revokeObjectURL(file.url));
		};
	}, [previews]);
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);

		const maxSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes
		// const maxSize = 1 * 1024 * 1024 * 1024; // 1GB in bytes

		const validFiles = [];
		const filePreviews = [];
		files.forEach(file => {
			if (file.size > maxSize) {
				setError(`File "${file.name}" exceeds the 2GB size limit.`);
			} else {
				validFiles.push(file);
				const fileURL = URL.createObjectURL(file);
				filePreviews.push({ name: file.name, type: file.type, url: fileURL });
			}
		});
		if (validFiles.length > 0) {
			setSelectedFiles(validFiles);
			setPreviews(filePreviews);
			setError(''); // Clear any previous errors
		}
	};

	const [listSelectedTags, listSetSelectedTags] = useState([]);

	// const [captchaValue, setCaptchaValue] = useState(null);

	const handleSubmit = event => {
		event.preventDefault();

		// if (selectedCategoriesOptions.length > 0) {
		if (selectedFiles) {     // for multiple file system

			// if (!captchaValue) {
			// 	alert('Please complete the CAPTCHA');
			// 	return;
			// }

			const formData = new FormData();

			formData.append('date', date);
			selectedAttackPlaceCategoriesOptions.forEach((opt) => {
				formData.append('attack_places', opt);
			});
			selectedCategoriesOptions.forEach((opt) => {
				formData.append('categories', opt);
			});
			formData.append('short_description', shortDescription);
			formData.append('description', description);
			// for multiple file system
			selectedFiles.forEach((file) => {
				formData.append('files', file); // Append each file with a 'files[]' key
			});
			formData.append('contributor_name', name);
			formData.append('victim_name', victim);
			formData.append('victim_address', address);
			formData.append('victim_occupation', occupation);
			formData.append('email', email);
			formData.append('victim_condition', victimConditionOptionValue);
			listSelectedTags.forEach((opt) => {
				formData.append('tags', opt);
			});

			// formData.append('captchaValue', captchaValue); // for captcha

			dispatch(archiveDataSubmit(formData, token));
			setUploadFlag(true);

			// setCaptchaValue('');
		}
		else {
			toast.error('Please select of at least one file to send.');
		}
	};

	const handleCreateHistorySubmit = () => {

		dispatch(archiveHistorySubmit(data.id, date, selectedAttackPlaceCategoriesOptions, selectedCategoriesOptions, shortDescription, description, name, victim, address, occupation, email, victimConditionOptionValue, 'home', '', '', '', '', token, userName.first_name, userName.last_name, listSelectedTags, '', ''));

		setDate('');
		setSelectedAttackPlaceCategoriesOptions([]);
		setSelectedCategoriesOptions([]);
		listSetSelectedTags([]);
		setShortDescription('');
		setDescription('');
		setSelectedFiles(null);  // for multiple file system
		setPreviews([]);  // for multiple file system
		setName('');
		setVictim('');
		setAddress('');
		setOccupation('');
		setEmail('');
		setVictimConditionOptionValue('');
	};

	useEffect(() => {
		if (data.success) {
			toast.success(data.success);
			setUploadFlag(false);
		}
		if (data.error.message) {
			toast.error(data.error.message);
			setUploadFlag(false);
		}
	}, [data.error.message, data.success, dispatch]);

	useEffect(() => {
		if (data.success && data.id) {
			handleCreateHistorySubmit();
		}
	}, [data.id, data.success]);

	//<Box maxW="1200px" mx="auto" px={4} py={8}></Box>
	return (
		<Layout>
			{/*<Container
				pb={{ base: '1', md: '2', }}
				px={{ base: '1', md: '2', }}
				maxWidth={"1400px"}	
				//maxW= "container.lg"									// bg={'gray'}
				mx={'auto'}
			>*/}
			<Box maxW="container.lg" mx="auto" px={4} py={8}>
				<Box
					// rowSpan={3}
					// colSpan={[5, 5, 3, 3, 3]}
					boxShadow='dark-lg'
					p={[3, 6]}
					rounded='md'
					w={'100%'}
					mb={5}
				>
					<VStack spacing={[5, 5, 5, 6]} align={'left'}>
						{/* Description Cards */}
						<DescriptionCards />
					</VStack>
				</Box>

				<Box
					boxShadow='dark-lg'
					p={[3, 6]}
					rounded='md'
					w={'100%'}
				>
					<Text
						fontSize={["lg", "lg", "2xl"]}
						fontWeight="bold"
						color='black'
						textAlign={'center'}
						mb={2}
					>
						{welcomeText}
					</Text>

					<Text
						fontSize={["lg", "lg", "2xl"]}
						fontWeight="bold"
						color='black'
						textAlign={'center'}
						mb={2}
					>
						{instructionText}
					</Text>

					<Box>
						{/* <Form onSubmit={handleSubmit}> */}
						<Form>
							{/* Name */}
							<Stack gap={[2, 5]} marginYZ={3} direction={['column',]}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{nameInputHeader}
								</Text>

								<Input
									name={nameInputId}
									id={nameInputId}
									variant='filled'
									size={["md", "md", "lg"]}
									width='100%'
									p={3}
									placeholder={nameInputPlaceholder}
									onChange={handleNameChange}
									value={name}
									h={'50px'}
								/>
							</Stack>

							{/* Email */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{emailInputHeader}
								</Text>

								<Textarea
									name={emailInputId}
									id={emailInputId}
									variant='filled'
									resize="vertical"  // "horizontal", "both", or "none"
									width='100%'
									p={3}
									placeholder={emailInputPlaceholder}
									onChange={handleEmailChange}
									value={email}
									h={'50px'}
								/>
							</Stack>

							{/* victim  */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{victimInputHeader}
								</Text>

								<Input
									name={victimInputId}
									id={victimInputId}
									variant='filled'
									size={["md", "md", "lg"]}
									width='100%'
									p={3}
									placeholder={victimInputPlaceholder}
									onChange={handleVictimChange}
									value={victim}
									h={'50px'}
								/>
							</Stack>

							{/* victim address  */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{addressInputHeader}
								</Text>

								<Textarea
									name={addressInputId}
									id={addressInputId}
									variant='filled'
									resize="vertical"  // "horizontal", "both", or "none"
									width='100%'
									p={3}
									placeholder={addressInputPlaceholder}
									onChange={handleAddressChange}
									value={address}
									h={'50px'}
								/>
							</Stack>

							{/* victim occupation  */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{occupationInputHeader}
								</Text>

								<Textarea
									name={occupationInputId}
									id={occupationInputId}
									variant='filled'
									resize="vertical"  // "horizontal", "both", or "none"
									width='100%'
									p={3}
									placeholder={occupationInputPlaceholder}
									onChange={handleOccupationChange}
									value={occupation}
									h={'50px'}
								/>
							</Stack>

							{/* Categories */}
							{/* <Stack gap={[2, 5]} marginY={3} direction={['column', 'column', 'row']}> */}
							<Stack gap={[2, 5]} marginY={3} direction={['column']}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{categoriesInputHeader}
								</Text>

								{/* <Box ml={5} w={['90%', '70%', '50%', '30%']}> */}
								<Box w={['100%']}>
									{/* <Button onClick={toggleDropdown}>
									{selectedCategoriesOptions.length > 0
										? `Total Selected (${selectedCategoriesOptions.length})`
										: `${categoriesInputPlaceholder}`}
								</Button> */}

									{/* {isOpen && ( */}
									<Box border="1px solid" borderColor="gray.200" borderRadius="md" p={2} bg="white">
										{categoriesOptions.map((option) => (
											<Box key={option.value}>
												<Checkbox
													colorScheme='red'
													isChecked={selectedCategoriesOptions.includes(option.value)}
													onChange={() => handleCheckboxChange(option.value)}
												>
													{option.label}
												</Checkbox>
											</Box>
										))}
									</Box>
									{/* )} */}
								</Box>
							</Stack>

							{/* Attack Place Categories */}
							<Stack gap={[2, 5]} marginY={3} direction={['column']}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{attackPlaceCategoriesInputHeader}
								</Text>

								{/* <Box ml={5} w={['90%', '70%', '50%', '30%']}> */}
								<Box>
									{/* <Button onClick={toggleDropdown}>
									{selectedCategoriesOptions.length > 0
										? `Total Selected (${selectedCategoriesOptions.length})`
										: `${categoriesInputPlaceholder}`}
								</Button> */}

									{/* {isOpen && ( */}
									<Box border="1px solid" borderColor="gray.200" borderRadius="md" p={2} bg="white">
										{attackPlaceCategoriesOptions.map((option) => (
											<Box key={option.value}>
												<Checkbox
													colorScheme='red'
													isChecked={selectedAttackPlaceCategoriesOptions.includes(option.value)}
													onChange={() => handleAttackPlaceCheckboxChange(option.value)}
												>
													{option.label}
												</Checkbox>
											</Box>
										))}
									</Box>
									{/* )} */}
								</Box>
							</Stack>

							{/* Victim Condition */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'row']}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{victimConditionInputHeader}
								</Text>

								<RadioGroup onChange={setVictimConditionOptionValue} value={victimConditionOptionValue}>
									<Stack direction={['column', "column", 'row']}>
										{victimConditionOptions.map((radioOption) => (
											<Radio colorScheme="red" key={radioOption.value} value={radioOption.value}>
												{radioOption.label}
											</Radio>
										))}
									</Stack>
								</RadioGroup>
							</Stack>

							{/* short Description */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{shortDescriptionInputHeader}
								</Text>

								<Textarea
									name={shortDescriptionInputId}
									id={shortDescriptionInputId}
									variant='filled'
									resize="vertical"  // "horizontal", "both", or "none"
									width='100%'
									p={3}
									placeholder={shortDescriptionInputPlaceholder}
									onChange={handleShortDescriptionChange}
									value={shortDescription}
								/>
							</Stack>

							{/* Description */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{descriptionInputHeader}
								</Text>

								<Textarea
									name={descriptionInputId}
									id={descriptionInputId}
									variant='filled'
									resize="vertical"  // "horizontal", "both", or "none"
									width='100%'
									p={3}
									placeholder={descriptionInputPlaceholder}
									onChange={handleDescriptionChange}
									value={description}
								/>
							</Stack>

							{/* Date */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'row']}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{dateInputHeader}
								</Text>

								<Input
									id={dateInputId}
									name={dateInputId}
									placeholder={dateInputPlaceholder}
									size='md'
									type='date'
									minLength="4"
									maxLength="8"
									value={date}
									onChange={handleDateChange}
								/>
							</Stack>

							{/* for Upload  */}
							<Box
								p={[1, 2]}
								mb={6}
								boxShadow={'md'}
								mx={'auto'}
							>
								<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
									<Text
										fontSize={["md", "md", "lg"]}
										fontWeight="semibold"
										color='black'
										textAlign={'left'}
									>
										{fileInputHeader}
									</Text>

									<Input
										type="file"
										onChange={handleFileChange}
										accept="application/pdf,video/*, audio/*, image/*, application/msword"
										variant='filled'
										multiple
									/>
								</Stack>

								{error && <p style={{ color: 'red' }}>{error}</p>}

								{/* // for multiple file system */}
								<Grid
									templateRows='repeat(1, 1fr)'
									templateColumns='repeat(4, 1fr)'
								>
									{previews.map((file, index) => (
										<GridItem
											colSpan={[4, 2, 1]}
											p='2'
											key={index}
										>
											{
												file.type.includes('pdf') ? (
													<Box h={['200px', "300px", "400px", '500px']}>
														<iframe
															src={file.url}
															title={file.name}
															width="100%"
															height="100%"
														></iframe>
													</Box>
												) : file.type.includes('image') ? (
													<Box w={'100%'} mx={'auto'}>
														<img
															src={file.url}
															alt={file.name}
															style={{ maxWidth: '100%', height: 'auto' }}
														/>
													</Box>
												) : (
													<video
														controls
														width="500"
														src={file.url}
													>
														Your browser does not support the video tag.
													</video>
												)
											}
										</GridItem>
									))}
								</Grid>

								{/* // upload before submit  */}
								{/* <Button
									bgColor={selectedFiles ? 'blue' : 'gray'}
									p={1} mt={2}
									borderRadius={'lg'}
									color={'white'}
									isDisabled={selectedFiles ? false : true}
									onClick={handleUpload}
								>
									Upload
								</Button> */}
							</Box>

							{/* tags */}
							<Stack gap={[0, 0]} marginY={3} direction={['column']}>
								<Text
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
								>
									{/* {dateInputHeader} */} Tags
								</Text>

								<Box>
									<Tags
										embedded={true}
										listSelectedTags={listSelectedTags}
										listSetSelectedTags={listSetSelectedTags}
									/>
								</Box>
							</Stack>

							<Text textAlign={'center'} my={2}>
								<Button
									size="lg"
									w="full"
									bg={"red.600"}
									color={"white"}
									_hover={{ bg: "red.700" }}
									px={4}
									py={1}
									borderRadius={'lg'}
									isDisabled={uploadFlag}
									type='submit'
									onClick={(event) => {
										handleSubmit(event);
									}}
								>
									{uploadFlag ? `${submitWaitingText}` : `${submitButtonText}`}
								</Button>
							</Text>
						</Form>
					</Box>
				</Box >
			</Box>
		</Layout>
	);
};

export default Incident;
