import React, { } from "react"
import {
    Select,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    Box,
    Checkbox,
    Textarea,
} from "@chakra-ui/react";

import TagField from "../Pages/Tag";
import EventField from "../Pages/Event";
import districtUpazilaData from './districtUpazilaData';

const EditModal = (
    {
        isOpen,
        onClose,
        date,
        handleDateChange,
        reviewDate,
        // handleReviewDateChange,
        selectedDistrict,
        handleDistrictChange,
        upazilas,
        selectedUpazila,
        handleUpazilaChange,
        title,
        handleTitleChange,
        url,
        handleURLChange,
        categoriesOptions,
        selectedCategoriesOptions,
        handleCheckboxChange,
        handleSourceTypeChange,
        selectedSourceTypeOption,
        contributorName,
        handleContributorNameChange,
        comment,
        handleCommentChange,
        death,
        handleDeathChange,
        injury,
        handleInjuryChange,
        propertyValue,
        handlePropertyValueChange,
        noOfHouses,
        handleNoOfHousesChange,
        propertyVandalized,
        handlePropertyVandalizedChange,
        handleReviewStatusChange,
        selectedReviewStatusOption,
        optionsReviewStatus,
        listSelectedTags,
        listSetSelectedTags,
        handleSubmit,
        options,
        listSelectedEvents,
        listSetSelectedEvents,
    }
) => {


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit Document</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* date */}
                    <FormControl >
                        <FormLabel>Add Date</FormLabel>
                        <Input
                            type='date'
                            value={date}
                            onChange={handleDateChange}
                        />
                    </FormControl>

                    {/* reviewDate */}
                    <FormControl mt={4}>
                        <FormLabel>Review Date</FormLabel>
                        <Input
                            type='date'
                            value={reviewDate}
                            readOnly
                        // disabled
                        // onChange={handleReviewDateChange}
                        />
                    </FormControl>

                    {/* district  */}
                    <FormControl mt={4} id="district">
                        <FormLabel>District</FormLabel>
                        <Select
                            placeholder="Select District"
                            size="md"
                            variant="filled"
                            focusBorderColor="gray.500"
                            borderRadius="md"
                            boxShadow="sm"
                            onChange={handleDistrictChange}
                            value={selectedDistrict}
                        >
                            {Object.keys(districtUpazilaData)?.map((district) => (
                                <option key={district} value={district}>
                                    {district}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    {/* upazila  */}
                    <FormControl mt={4} id="upazila">
                        <FormLabel>Upazila</FormLabel>
                        <Select
                            placeholder="Select Upazila"
                            size="md"
                            variant="filled"
                            focusBorderColor="gray.500"
                            borderRadius="md"
                            boxShadow="sm"
                            isDisabled={!selectedDistrict}
                            onChange={handleUpazilaChange}
                            value={selectedUpazila}
                        >
                            {upazilas?.map((upazila) => (
                                <option key={upazila} value={upazila}>
                                    {upazila}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    {/*title  */}
                    <FormControl mt={4}>
                        <FormLabel>Title</FormLabel>
                        <Input type="text"
                            value={title}
                            onChange={handleTitleChange}
                        />
                    </FormControl>

                    {/* source url  */}
                    <FormControl mt={4}>
                        <FormLabel>Source URL</FormLabel>
                        <Input type="url"
                            value={url}
                            onChange={handleURLChange}
                        />
                    </FormControl>

                    {/* categories  */}
                    <FormControl mt={4}>
                        <FormLabel>Categories</FormLabel>
                        {categoriesOptions.map((option) => (
                            <Box key={option.value}>
                                <Checkbox
                                    colorScheme="red"
                                    isChecked={selectedCategoriesOptions.includes(option.value)}
                                    onChange={() => handleCheckboxChange(option.value)}
                                >
                                    {option.label}
                                </Checkbox>
                            </Box>
                        ))}
                    </FormControl>

                    {/* source type description  */}
                    <FormControl mt={4}>
                        <FormLabel>Source Type Description</FormLabel>
                        {/* <Textarea defaultValue={editData?.description} /> */}
                        <Select
                            size={["md", "md", "lg"]}
                            variant="filled"
                            focusBorderColor="gray.500"
                            borderRadius="md"
                            boxShadow="sm"
                            placeholder="Select Source Type"
                            onChange={handleSourceTypeChange}
                            value={selectedSourceTypeOption}
                        >
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    {/* contributor_name  */}
                    <FormControl mt={4}>
                        <FormLabel>Contributor Name</FormLabel>
                        <Input type="text"
                            value={contributorName}
                            onChange={handleContributorNameChange}
                        />
                    </FormControl>

                    {/* Comments  */}
                    <FormControl mt={4}>
                        <FormLabel>Add Comments</FormLabel>
                        <Textarea
                            type="text"
                            value={comment}
                            onChange={handleCommentChange}
                        />
                    </FormControl>

                    {/* Death  */}
                    <FormControl mt={4}>
                        <FormLabel>Death</FormLabel>
                        <Input type="number"
                            value={death}
                            onChange={handleDeathChange}
                        />
                    </FormControl>

                    {/* Injury */}
                    <FormControl mt={4}>
                        <FormLabel>Injury</FormLabel>
                        <Input type="number"
                            value={injury}
                            onChange={handleInjuryChange}
                        />
                    </FormControl>

                    {/* Property Value */}
                    <FormControl mt={4}>
                        <FormLabel>Property Value</FormLabel>
                        <Input
                            type="number"
                            value={propertyValue}
                            onChange={handlePropertyValueChange}
                        />
                    </FormControl>

                    {/* Number Of Houses */}
                    <FormControl mt={4}>
                        <FormLabel>Number Of Houses</FormLabel>
                        <Input type="number"
                            value={noOfHouses}
                            onChange={handleNoOfHousesChange}
                        />
                    </FormControl>

                    {/* Property Vandalized */}
                    <FormControl mt={4}>
                        <FormLabel>Property Vandalized</FormLabel>
                        <Input type="number"
                            value={propertyVandalized}
                            onChange={handlePropertyVandalizedChange}
                        />
                    </FormControl>

                    {/* Review Status */}
                    <FormControl mt={4}>
                        <FormLabel>Review Status</FormLabel>
                        {/* <Textarea defaultValue={editData?.description} /> */}
                        <Select
                            placeholder={"Select Review"}
                            size={["md", "md", "lg"]}
                            variant="filled"
                            focusBorderColor="blue.500"
                            borderRadius="md"
                            boxShadow="sm"
                            onChange={handleReviewStatusChange}
                            value={selectedReviewStatusOption}
                        >
                            {optionsReviewStatus.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Tags */}
                    <FormControl mt={4}>
                        <FormLabel>
                            Tags
                        </FormLabel>
                        <TagField
                            embedded={true}
                            onlySearch={false}
                            deleteButton={false}
                            listSelectedTags={listSelectedTags}
                            listSetSelectedTags={listSetSelectedTags}
                        />
                    </FormControl>

                    {/* Events */}
                    <FormControl mt={4}>
                        <FormLabel>
                            Events
                        </FormLabel>
                        <EventField
                            embedded={true}
                            onlySearch={false}
                            deleteButton={false}
                            listSelectedEvents={listSelectedEvents}
                            listSetSelectedEvents={listSetSelectedEvents}
                        />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button
                        //colorScheme="blue" 
                        mr={3}
                        // onClick={onClose}
                        bgColor="gray.500"
                        color="white"
                        _hover={{ bg: "gray.900", color: "white" }}
                        onClick={handleSubmit}
                    >
                        Update
                    </Button>
                    <Button
                        variant="ghost"
                        onClick={onClose}
                        bgColor="red.700"
                        color="white"
                        _hover={{ bg: "red.900", color: "white" }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

    )
}

export default EditModal;