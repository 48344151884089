import axios from "axios";
import {
    requestGetListDocuments,
    getListDocumentsSuccess,
    getListDocumentsFailure,
    requestUpdateDocument,
    updateDocumentSuccess,
    updateDocumentFailure,
    requestUpdateEditHistoryDocument,
    updateEditHistoryDocumentSuccess,
    updateEditHistoryDocumentFailure,
    requestFileDownload,
    fileDownloadSuccess,
    fileDownloadFailure,
    requestGetDocumentEditHistory,
    getDocumentEditHistorySuccess,
    getDocumentEditHistoryFailure,
    requestGetSearchResult,
    getSearchResultSuccess,
    getSearchResultFailure,
} from "../../ActionCreator/listDocuments";

import { data_update_edit_history_url, data_update_url, file_download_url, get_document_edit_history_url, get_list_documents_url, get_search_list_documents_url } from "../../../allApiPath";


export const getListDocumentsData = (skip, limit, token, processed) => async (dispatch, getState) => {
    dispatch(requestGetListDocuments())

    axios.get(get_list_documents_url(skip, limit, processed), {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getListDocumentsSuccess(response.data));
        }, error => {
            dispatch(getListDocumentsFailure(error));
        })
}


export const downloadFile = (filename, token) => async (dispatch) => {
    dispatch(requestFileDownload())

    try {
        const response = await axios.post(file_download_url, {
            filename: filename,
        }, {
            responseType: 'blob', // Important for file download
            headers: {
                'Authorization': token,
            }
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const fileName = filename.split('/').pop(); // Extract file name from the full path
        link.setAttribute('download', fileName); // Set the downloaded file's name

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch(fileDownloadSuccess("Download Success"));
    } catch (error) {
        console.error('Download error:', error);
        dispatch(fileDownloadFailure(error));
    }
};


export const dataUpdateSubmit = (
    id,
    date,
    currentDate,
    selectedDistrict,
    selectedUpazila,
    title,
    url,
    selectedCategoriesOptions,
    selectedSourceTypeOption,
    contributorName,
    comment,
    death,
    injury,
    propertyValue,
    noOfHouses,
    propertyVandalized,
    selectedReviewStatusOption,
    token,
    listSelectedTags,
    listSelectedEvents,
    download_again,
    download_id
) => async (dispatch, getState) => {
    dispatch(requestUpdateDocument())

    axios.post(data_update_url, {
        id: id,
        date: date,
        review_date: currentDate,
        location: selectedDistrict,
        upazila: selectedUpazila,
        title: title,
        source_url: url,
        categories: selectedCategoriesOptions,
        source_type: selectedSourceTypeOption,
        contributor_name: contributorName,
        comment: comment,
        death: parseInt(death),
        injury: parseInt(injury),
        property_value: propertyValue,
        number_of_houses: parseInt(noOfHouses),
        property_vandalized: parseInt(propertyVandalized),
        review_status: selectedReviewStatusOption,
        tags: listSelectedTags,
        events: listSelectedEvents,
        download_again: download_again,
        download_id: download_id,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(updateDocumentSuccess(response.data));
        }, error => {
            dispatch(updateDocumentFailure(error))
        })
}


export const dataUpdateEditHistorySubmit = (
    id,
    date,
    currentDate,
    selectedDistrict,
    selectedUpazila,
    title,
    url,
    selectedCategoriesOptions,
    selectedSourceTypeOption,
    contributorName,
    comment,
    death,
    injury,
    propertyValue,
    noOfHouses,
    propertyVandalized,
    selectedReviewStatusOption,
    token,
    listSelectedTags,
    listSelectedEvents,
    dataType,
    first_name,
    last_name,
) => async (dispatch, getState) => {
    dispatch(requestUpdateEditHistoryDocument())

    axios.post(data_update_edit_history_url, {
        data_type_id: id,
        date: date,
        review_date: currentDate,
        location: selectedDistrict,
        upazila: selectedUpazila,
        title: title,
        source_url: url,
        categories: selectedCategoriesOptions,
        source_type: selectedSourceTypeOption,
        contributor_name: contributorName,
        comment: comment,
        death: parseInt(death),
        injury: parseInt(injury),
        property_value: propertyValue,
        number_of_houses: parseInt(noOfHouses),
        property_vandalized: parseInt(propertyVandalized),
        review_status: selectedReviewStatusOption,
        tags: listSelectedTags,
        events: listSelectedEvents,
        data_type: dataType,
        first_name: first_name,
        last_name: last_name,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(updateEditHistoryDocumentSuccess(response.data));
        }, error => {
            dispatch(updateEditHistoryDocumentFailure(error))
        })
}


export const getDocumentEditHistoryData = (skip, limit, token, id) => async (dispatch, getState) => {
    dispatch(requestGetDocumentEditHistory())

    axios.get(get_document_edit_history_url(skip, limit, id), {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getDocumentEditHistorySuccess(response.data));
        }, error => {
            dispatch(getDocumentEditHistoryFailure(error));
        })
}


export const getSearchResult = (skip, limit, token, startDate, endDate, selectedSearch, selectedSearchOptions, listSearchSelectedTags, listSearchSelectedEvents) => async (dispatch, getState) => {
    dispatch(requestGetSearchResult())

    axios.post(get_search_list_documents_url, {
        skip: skip,
        limit: limit,
        source_type: selectedSearch,
        categories: selectedSearchOptions,
        start_date: startDate,
        end_date: endDate,
        tags: listSearchSelectedTags,
        events: listSearchSelectedEvents,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getSearchResultSuccess(response.data));
            // console.log(response.data);
        }, error => {
            dispatch(getSearchResultFailure(error));
        })
}
