import React from 'react';
import {
  Box,
  Checkbox,
  Flex,
  IconButton,
  Link,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Badge,
  Icon,
  Tooltip
} from "@chakra-ui/react";
import { DownloadIcon, ViewIcon, EditIcon, RepeatIcon, } from '@chakra-ui/icons';
import { FaCheck, FaClock, FaExclamationCircle } from 'react-icons/fa';


export default function LibraryResources({
  filteredData,
  decode,
  handleDownload,
  handlePreview,
  selectFlag,
  selectedFile,
  setSelectFile,
  handleEdit,
  handleEditHistory,
}) {

  const statusBadge = (status) => {
    switch (status) {
      case "clear":
        return (
          <Badge colorScheme="green" display="flex" justifyContent="center" alignItems="center" borderRadius={5} p={1}>
            <Icon as={FaCheck} w={3} h={3} mr={1} />
            Verified
          </Badge>
        )
      case "duplicate":
      case "garbage":
      case "need-more-documents":
        return (
          <Badge colorScheme="yellow" display="flex" justifyContent="center" alignItems="center" borderRadius={5} p={1}>
            <Icon as={FaClock} w={3} h={3} mr={1} />
            Further Review
          </Badge>
        )
      case 'New':
        return (
          <Badge colorScheme="blue" display="flex" justifyContent="center" alignItems="center" borderRadius={5} p={1}>
            <Icon as={FaExclamationCircle} w={3} h={3} mr={1} />
            New
          </Badge>
        )
      default:
        return null;
    }
  }
  // console.log(filteredData);

  return (
    <Box width="100%">
      <Table variant="simple" display={{ base: "none", md: "block" }} >
        <Thead >
          <Tr >
            {selectFlag && <Th><Checkbox /></Th>}
            <Th>Title/URL</Th>
            <Th>Category</Th>
            <Th>Source</Th>
            <Th>Status</Th>
            <Th textAlign="right">Actions</Th>
          </Tr>
        </Thead>

        <Tbody width="90vw">
          {
            filteredData.length > 0 ?
              filteredData.map((td, i) =>
                <Tr key={td.id}>
                  {
                    selectFlag &&
                    < Td >
                      <Checkbox
                        //bgColor={'gray'}
                        colorScheme={"gray"}
                        mb={[3, 3, 6]}
                        isChecked={selectedFile.includes(td.filename)}
                        onChange={() =>
                          setSelectFile((prev) =>
                            prev.includes(td.filename)
                              ? prev.filter((item) => item !== td.filename)
                              : [...prev, td.filename]
                          )
                        }
                      />
                    </Td>
                  }

                  <Td w='35vw'>
                    {td?.title && `Title: ${td.title}`}

                    {
                      td?.source_url &&
                      <>
                        <Text fontWeight="500" fontSize={'small'}>URL: </Text>
                        {/* <Text fontSize="sm" color="gray.500"> */}
                        <Link
                          //fontWeight="semibold"
                          wordBreak="break-word"
                          whiteSpace="normal"
                          href={td.source_url}
                          color="teal.600"
                          fontSize="sm"
                          mb={2}
                          isExternal
                        >
                          {decode(td?.source_url)} {/* Display the decoded URL */}
                        </Link>
                        {/* </Text> */}
                      </>
                    }
                  </Td>

                  <Td w="35vw">{Array.isArray(td?.categories) && td?.categories?.join(', ')}</Td>

                  <Td w="5vw">
                    {/* <Text
                        fontWeight="semibold"
                        wordBreak="break-word"
                        whiteSpace="normal"
                        color="gray.600"
                        fontSize="sm"
                        mb={2}
                      > */}
                    {td?.description ? `${td?.description.charAt(0).toUpperCase() + td.description.slice(1)}` :
                      `${td?.source_type.charAt(0).toUpperCase() + td.source_type.slice(1)}`}
                    {/* </Text> */}
                  </Td>

                  <Td w="10vw">{statusBadge(td?.review_status ? `${td?.review_status}` : "New")}</Td>

                  <Td w="15vw">
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-around" alignItems="center">
                      <Tooltip hasArrow label='Download' bg='gray.300' color='black' placement='top'>
                        <IconButton
                          isDisabled={"waiting" === td.status ? true : false}
                          onClick={() => handleDownload(td?.filename)}
                          icon={<DownloadIcon />}
                          aria-label="Download"
                          size="sm"
                        />
                      </Tooltip>

                      <Tooltip hasArrow label='Preview' bg='gray.300' color='black' placement='top'>
                        <IconButton
                          isDisabled={"waiting" === td.status ? true : false}
                          onClick={() => handlePreview(td)}
                          icon={<ViewIcon />}
                          aria-label="View"
                          size="sm"
                          ml={2}
                        />
                      </Tooltip>

                      <Tooltip hasArrow label='Edit' bg='gray.300' color='black' placement='top'>
                        <IconButton
                          onClick={() => handleEdit(td)}
                          icon={<EditIcon />}
                          aria-label="Edit"
                          size="sm"
                          ml={2}
                        />
                      </Tooltip>

                      <Tooltip hasArrow label='Edit History' bg='gray.300' color='black' placement='top'>
                        <IconButton
                          onClick={() => handleEditHistory(td.id)}
                          icon={<RepeatIcon />}
                          aria-label="Repeat"
                          size="sm"
                          ml={2}
                        />
                      </Tooltip>
                    </Box>
                  </Td>
                </Tr>
              )
              :
              <Tr>
                <Td>
                  <Text textAlign="center" my={4}>
                    No data found for the current search/filter.
                  </Text>
                </Td>
              </Tr>
          }
        </Tbody>
      </Table>

      {/* Card layout for smaller screens */}
      <Box display={{ base: "block", md: "none" }}>
        {
          filteredData.length > 0 ?
            filteredData.map((td, i) =>
              <Box
                key={td.id}
                bg="white"
                shadow="md"
                rounded="lg"
                p={4}
                mb={4}
              >
                <Flex align="center" mb={2}>
                  {
                    selectFlag &&
                    <Checkbox
                      mr={2}
                      // mb={[3, 3, 6]}
                      isChecked={selectedFile.includes(td.filename)}
                      onChange={() =>
                        setSelectFile((prev) =>
                          prev.includes(td.filename)
                            ? prev.filter((item) => item !== td.filename)
                            : [...prev, td.filename]
                        )
                      }
                    />
                  }

                  <Box>
                    {
                      td?.title &&
                      <Text fontSize="sm" color="gray.500">
                        <Text as={'span'} fontSize="md" color="black"><strong>Title: </strong></Text>
                        {td.title}
                      </Text>
                    }

                    {
                      td?.source_url &&
                      <Text fontSize="sm" color="gray.500">
                        <Text as={'span'} fontSize="md" color="black"><strong>URL: </strong></Text>
                        <Link
                          //fontWeight="semibold"
                          wordBreak="break-word"
                          whiteSpace="normal"
                          href={td.source_url}
                          color="teal.600"
                          // fontSize="sm" 
                          mb={2}
                          isExternal
                        >
                          {decode(td?.source_url)} {/* Display the decoded URL */}
                        </Link>
                      </Text>
                    }
                  </Box>
                </Flex>

                <Text><strong>Category:</strong> {Array.isArray(td?.categories) && td?.categories?.join(', ')}</Text>

                <Text>
                  <strong>Source:</strong>{' '}
                  {td?.description ? `${td?.description.charAt(0).toUpperCase() + td.description.slice(1)}` :
                    `${td?.source_type.charAt(0).toUpperCase() + td.source_type.slice(1)}`}
                </Text>

                <Box mb={2} display={'flex'} >
                  <Text mr={3}><strong>Status:</strong></Text>
                  {statusBadge(td?.review_status ? `${td?.review_status}` : "New")}
                </Box>

                <Flex justify="center" gap={2}>
                  <IconButton
                    isDisabled={"waiting" === td.status ? true : false}
                    onClick={() => handleDownload(td?.filename)}
                    icon={<DownloadIcon />}
                    aria-label="Download"
                    size="sm"
                  />

                  <IconButton
                    isDisabled={"waiting" === td.status ? true : false}
                    onClick={() => handlePreview(td)}
                    icon={<ViewIcon />}
                    aria-label="View"
                    size="sm"
                  />

                  <IconButton
                    onClick={() => handleEdit(td)}
                    icon={<EditIcon />}
                    aria-label="Edit"
                    size="sm"
                  />

                  <IconButton
                    onClick={() => handleEditHistory(td.id)}
                    icon={<RepeatIcon />}
                    aria-label="Repeat"
                    size="sm"
                  />
                </Flex>
              </Box>
            )
            :
            <Text textAlign="center" my={4}>
              No data found for the current search/filter.
            </Text>
        }
      </Box >
    </Box >
  )
};

